import React from 'react';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { Intro } from '../components/Intro';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <Intro>
      <h2>Page Not Found</h2>
    </Intro>

    <div className="container">
      <div className="content">
        <div className="grid">
          <div className="col" style={{ minHeight: 150 }}>
            <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
